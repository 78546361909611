import SectionWithCards from '@/components/SectionWithCards/SectionWithCards'
import Layout from 'layout'
import { ReactElement } from 'react'
import BannerBlock from 'shared/sections/BannerBlock'
import DashboardBlock from 'shared/sections/DashboardBlock'
import FreeTrialBlock from 'shared/sections/FreeTrialBlock'
import StartBlock from 'shared/sections/StartBlock'
import { withServerSideProps } from 'shared/withPageProps'
import { IntroCardData, IntroContentData } from 'utils/content'

export const getServerSideProps = withServerSideProps(async () => {
	return {
		props: {}
	}
})

export default function HomePage() {
	return (
		<>
			<BannerBlock />
			<SectionWithCards
				title={
					<>
						Introducing ASTA:
						<br />
						The Autonomous Software Testing Agent
					</>
				}
				content={IntroContentData.content}
				titleStyles={{ mt: 4 }}
				cardsData={IntroCardData}
			/>
			<StartBlock />
			<DashboardBlock />
			<FreeTrialBlock />
		</>
	)
}

HomePage.getLayout = function getLayout(page: ReactElement) {
	return (
		<Layout title="Home" isLoggedIn={page.props.isLoggedIn}>
			{page}
		</Layout>
	)
}
