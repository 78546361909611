/**
 * A reusable card layout that can display an icon, title, and a list of content items.
 *
 * @component
 * @param {string} [props.icon] - URL of the icon to display
 * @param {string} props.title - The title of the card
 * @param {string[]} props.content - An array of strings to be displayed as list items
 * @param {Object} [props.titleStyles] - Custom styles for the title
 * @param {Object} [props.contentStyles] - Custom styles for the content
 *
 * @example
 * <CommonCard
 *   icon="/path/to/icon.png"
 *   title="Card Title"
 *   content={["Item 1", "Item 2", "Item 3"]}
 *   titleStyles={{ color: "blue" }}
 *   contentStyles={{ fontSize: "14px" }}
 * />
 */

import React, { PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'
import MainCard from '../MainCard/MainCard'
import { useTheme } from '@mui/material/styles'

interface CommonCardProps {
	icon?: string
	title: string
	content: string[]
	titleStyles?: object
	contentStyles?: object
}

const CommonCard: React.FC<PropsWithChildren<CommonCardProps>> = ({ icon, title, content, titleStyles, contentStyles }) => {
	const theme = useTheme()

	return (
		<MainCard
			contentSX={{ p: 3, cursor: 'pointer' }}
			sx={{
				height: '100%',
				backgroundColor: theme.palette.mode === 'dark' ? theme.palette.custom.main : theme.palette.grey[50],
				'&:hover': {
					bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey.A700 : 'white'
				}
			}}
		>
			<Box sx={{ textAlign: 'start' }}>
				{icon && <img src={icon} alt={`${title} icon`} width={48} height={48} />}
				<Typography
					variant="h4"
					sx={{
						fontWeight: 600,
						my: 2,
						color: theme.palette.mode === 'dark' ? theme.palette.custom.light : 'black',
						...titleStyles
					}}
				>
					{title}
				</Typography>
				<Typography
					variant="body1"
					component="div"
					sx={{
						color: theme.palette.mode === 'dark' ? theme.palette.custom.light : 'black',
						...contentStyles
					}}
				>
					<ul>
						{content.map(_content => (
							<li key={_content} style={{ listStyleType: 'none' }}>
								{_content}
							</li>
						))}
					</ul>
				</Typography>
			</Box>
		</MainCard>
	)
}

export default CommonCard
