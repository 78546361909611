/**
 * A component that combines ContentBlock and CommonCard to create a section with a title, content, and a grid of cards.
 *
 * @component
 * @param {(string|JSX.Element)} props.title - The title of the section
 * @param {string} props.content - The main content of the section
 * @param {Array<Object>} props.cardsData - An array of objects representing card data
 * @param {string} [props.cardsData[].icon] - URL of the icon for a card
 * @param {string} props.cardsData[].title - The title of a card
 * @param {string[]} props.cardsData[].content - An array of strings for card content
 * @param {Object} [props.titleStyles] - Custom styles for the section title
 * @param {Object} [props.contentStyles] - Custom styles for the section content
 * @param {Object} [props.cardTitleStyles] - Custom styles for card titles
 * @param {Object} [props.cardContentStyles] - Custom styles for card content
 *
 * @example
 * const cardsData = [
 *   { icon: "/icon1.png", title: "Card 1", content: ["Item 1", "Item 2"] },
 *   { icon: "/icon2.png", title: "Card 2", content: ["Item 3", "Item 4"] },
 *   { title: "Card 3", content: ["Item 5", "Item 6"] },
 * ];
 *
 * <SectionWithCards
 *   title="Section Title"
 *   content="This is the main content of the section."
 *   cardsData={cardsData}
 *   titleStyles={{ color: "purple" }}
 *   contentStyles={{ maxWidth: "600px" }}
 *   cardTitleStyles={{ fontWeight: "bold" }}
 *   cardContentStyles={{ color: "gray" }}
 * />
 */

import React, { PropsWithChildren } from 'react'
import { Container, Grid, Box } from '@mui/material'
import CommonCard from '@/components/CommonCard/CommonCard'
import ContentBlock from '@/components/ContentBlock/ContentBlock'
import { useTheme } from '@mui/material/styles'

interface SectionWithCardsProps {
	title: string | JSX.Element
	content: string
	titleStyles?: object
	contentStyles?: object
	cardsData: { icon?: string; title: string; content: string[] }[]
	cardTitleStyles?: object
	cardContentStyles?: object
}

const SectionWithCards: React.FC<PropsWithChildren<SectionWithCardsProps>> = ({
	title,
	content,
	cardsData,
	titleStyles,
	contentStyles,
	cardTitleStyles,
	cardContentStyles
}) => {
	const theme = useTheme()

	return (
		<Box
			sx={{
				width: '100%',
				backgroundColor: theme.palette.mode === 'dark' ? theme.palette.custom.main : theme.palette.grey[50]
			}}
		>
			<Container
				component="section"
				sx={{
					pt: { md: 2, xs: 1.5 },
					pb: { md: 10, xs: 2.5 },
					height: '100%',
					backgroundColor: theme.palette.mode === 'dark' ? theme.palette.custom.main : theme.palette.grey[50]
				}}
			>
				<Box sx={{ textAlign: 'center', mb: 4 }}>
					<ContentBlock
						title={title}
						content={content}
						titleStyles={{ mb: 2, ...titleStyles }}
						contentStyles={{
							mb: 4,
							mx: 'auto',
							maxWidth: { sm: '100%', md: '50%' },
							...contentStyles
						}}
					/>
				</Box>

				<Grid container justifyContent="center" spacing={2}>
					{cardsData.map(({ icon, title, content }) => (
						<Grid item xs={12} sm={6} md={4} key={title}>
							<CommonCard
								icon={icon}
								title={title}
								content={content}
								titleStyles={cardTitleStyles}
								contentStyles={cardContentStyles}
							/>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	)
}

export default SectionWithCards
