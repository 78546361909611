import { CheckCircleOutlined } from '@ant-design/icons'
import { Box, CardMedia, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ContentBlock from '@/components/ContentBlock/ContentBlock'

const DashboardBlock = () => {
	const theme = useTheme()

	const checkIcon = <CheckCircleOutlined style={{ color: theme.palette.primary.main, fontSize: '1.15rem' }} />

	const dashboardData = [
		'See overall test coverage and failure rate',
		'See what pages need attention most',
		'Zoom in on functionality or accessibility issues'
	]

	return (
		<Box
			component="section"
			sx={{
				overflow: 'hidden',
				position: 'relative',
				'&:after': {
					content: '""',
					position: 'absolute',
					width: '100%',
					height: '40%',
					bottom: 0,
					left: 0,
					//@ts-expect-error
					background: theme.palette.secondary[800],
					[theme.breakpoints.down('sm')]: { height: '60%' }
				},
				py: { md: 10, xs: 2.5 }, // Added padding for top and bottom
				backgroundColor: theme.palette.mode === 'dark' ? theme.palette.custom.main : theme.palette.grey[50]
			}}
		>
			<Container
				sx={{
					position: 'relative',
					zIndex: 1,
					textAlign: 'center'
				}}
			>
				<ContentBlock
					title="Actionable Results"
					content="The dashboard report provides a graphical summary of test results. You
          can drill down to see details for reported errors."
					contentStyles={{ mb: 4, maxWidth: 600, mx: 'auto' }}
				/>

				<CardMedia
					sx={{ width: '75%', maxWidth: 910, mx: 'auto', mb: 3 }} // Centralized and set max width
					component="img"
					image="assets/images/landing/asta-dashboard.png"
					alt="ASTA Dashboard"
					height={570}
					style={{ objectFit: 'cover' }}
				/>

				<Box
					sx={{
						maxWidth: '75%',
						mx: 'auto',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: { md: 'row', xs: 'column' }
					}}
				>
					{dashboardData.map(_content => (
						<Box
							key={_content}
							sx={{
								display: 'flex',
								alignItems: 'start',
								mb: 2,
								maxWidth: { md: '33%', xs: '100%' },
								textAlign: 'start'
							}}
						>
							{checkIcon}
							<Typography variant="body1" color="secondary" sx={{ ml: 1 }}>
								{_content}
							</Typography>
						</Box>
					))}
				</Box>
			</Container>
		</Box>
	)
}

export default DashboardBlock
