import { Box, CardMedia, Container } from '@mui/material'
import ContentBlock from '@/components/ContentBlock/ContentBlock'
import { useTheme } from '@mui/material/styles'

const StartBlock = () => {
	const theme = useTheme()

	return (
		<Box
			component="section"
			sx={{
				backgroundColor: theme.palette.mode === 'dark' ? theme.palette.custom.dark : 'secondary.main',
				py: { md: 5, xs: 2.5 } // Combined margin top and bottom into padding
			}}
		>
			<Container sx={{ position: 'relative', textAlign: 'center' }}>
				<ContentBlock
					title="So Smart It's Simple"
					content={`From the Start page, enter the base URL of your application and click
          "Start". That's it! ASTA explores your application, testing as it
          goes. The agent logs all tests and results for reporting and problem
          analysis. The agent also builds a model of the application and uses it
          to optimize testing and report test coverage.`}
					contentStyles={{
						mb: 4,
						mx: 'auto',
						maxWidth: { sm: '100%', md: '50%' }
					}}
				/>
				<CardMedia
					sx={{ maxWidth: 605, mx: 'auto' }} // Set a max width for the image and center it
					component="img"
					image="assets/images/landing/asta-start-page.png"
					alt="ASTA start page"
					height={357}
					style={{ objectFit: 'cover' }}
				/>
			</Container>
		</Box>
	)
}

export default StartBlock
