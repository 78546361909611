import { useContext } from 'react'
import { EyeOutlined } from '@ant-design/icons'
import { Box, Button, CardMedia, Container, Link, Typography } from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import { AuthContext } from 'contexts/auth'
import NextLink from 'next/link'
import { commonPageStyles } from 'shared/common-styles'
import { styled } from '@mui/system'

const PrimarySpan = styled('span')(({ theme }) => ({
	color: theme.palette.primary.main
}))

const BannerBlock = () => {
	const { logIn } = useContext(AuthContext)
	return (
		<Box component="section" sx={commonPageStyles.landing}>
			<CardMedia
				sx={{
					position: 'absolute',
					width: { md: '78%', lg: '70%', xl: '65%' },
					right: { md: '-14%', lg: '-4%', xl: '-2%' },
					top: { md: '16%', lg: '12%', xl: '8%' },
					zIndex: 1,
					display: { xs: 'none', md: 'block' }
				}}
			>
				<img
					src="assets/images/landing/bg-mockup-default.png"
					alt="hero-image"
					width={1500}
					height={800}
					style={{ objectFit: 'cover' }}
				/>
			</CardMedia>
			<Container
				sx={{
					minHeight: '80vh',
					display: 'flex',
					alignItems: 'center',
					textAlign: { xs: 'center', md: 'left' } // Simplified alignment
				}}
			>
				<Box sx={{ flex: { xs: 1, md: 0.4 } }}>
					<Typography
						variant="h1"
						color="white"
						sx={{
							fontSize: {
								xs: '3rem',
								sm: '3.25rem',
								md: '3.5rem'
							},
							fontWeight: 700,
							lineHeight: 1.3
						}}
					>
						Automate Tests Without the Stress
					</Typography>
					<Typography
						variant="h6"
						component="div"
						color="white"
						sx={{
							fontSize: { xs: '1rem', md: '1.25rem' },
							fontWeight: 400,
							lineHeight: 1.4,
							mt: 2 // Added margin for spacing
						}}
					>
						ASTA performs <PrimarySpan>fully autonomous</PrimarySpan> functional testing of web applications
					</Typography>
					<Box
						sx={{
							mt: 4,
							display: 'flex',
							flexDirection: { xs: 'column', xss: 'row' },
							justifyContent: { xs: 'center', md: 'flex-start' },
							gap: 2 // Added gap for spacing
						}}
					>
						<AnimateButton>
							<NextLink
								href="https://outlook.office365.com/owa/calendar/ASTADemoScheduling@bpsconsulting.com/bookings/"
								passHref
								target="_blank"
							>
								<Button size="large" color="primary" variant="outlined" component={Link} target="_blank">
									Get a demo
								</Button>
							</NextLink>
						</AnimateButton>
						<AnimateButton>
							<Button
								component={Link}
								size="large"
								color="primary"
								variant="contained"
								startIcon={
									<EyeOutlined
										style={{
											fontSize: '1.15rem'
										}}
									/>
								}
								onClick={() => logIn()}
							>
								Try ASTA for Free
							</Button>
						</AnimateButton>
					</Box>
				</Box>
			</Container>
		</Box>
	)
}

export default BannerBlock
